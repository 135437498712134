import Vue from 'vue'
import videojs from 'video.js'
Vue.prototype.$video = videojs
import Moment from 'moment'
import { EVENT_SEVERITY } from '@/util/Enums'

const Component = videojs.getComponent('Component')

class EventBarCustom extends Component {

  videoStarted = false;

  constructor(player, options = {}) {
    super(player, options)
    this.addEvents(options.events)
  }

  createEl() {
    return videojs.dom.createEl('div', {
      className: 'flex',
      style: 'margin: 0 10px;'
    })
  }

  addEvents(events = []) {

    const player = this.player()
    player.on('play', () => {
      this.renderTooltips(events)
    })

  }

  renderTooltips(events = []) {

    this.videoStarted = true
    // Obter o elemento da barra de progresso
    const progressBar = this.player().controlBar.progressControl.seekBar.el()

    // Obter a largura da barra de progresso em pixels
    const progressBarWidth = progressBar.offsetWidth

    const el = this.el()

    // Start event type should be the first event to show on video timeline
    const index = events.findIndex(event => {
      return event.type === 'start'
    })
    events = events.slice(index)

    const firstEvent = events[0]
    const lastEvent = events[events.length - 1]

    events.forEach(event => {

      const progressBarStartDate = Moment(firstEvent.data.emitted_at).toDate()
      const progressBarEndDate = Moment(lastEvent.data.emitted_at).toDate()
      const eventDate = Moment(event.data.emitted_at).toDate()
      const eventPosition = this.getEventPosition(eventDate, progressBarStartDate, progressBarEndDate, progressBarWidth)

      const divTooltip = videojs.dom.createEl('div', {
        style: `border-radius: 10px; width: 15px; height: 15px; position: absolute; top: 28px; left: ${eventPosition}px; ${this.getSeverityClass(event.severity)}`,
        id: `tooptip-div-id-${event.id}`,
        className: 'tooltip'
      })

      const eventElem = videojs.dom.createEl('div', {
        className: 'tooltip-text',
      })

      const textElem = videojs.dom.createEl('div', {
        className: 'tooltip-text-content',
      })

      const textElemEvent = videojs.dom.createEl('div', {
        textContent: event.data.text,
        style: 'padding-top: 10px; font-size: 13px;'
      })

      const textElemData = videojs.dom.createEl('div', {
        textContent: `Data: ${Moment(event.data.emitted_at).format('DD/MM/YYYY HH:mm')}`,
      })

      videojs.dom.prependTo(eventElem, divTooltip)
      videojs.dom.prependTo(textElem, eventElem)

      videojs.dom.prependTo(textElemEvent, textElem)
      videojs.dom.prependTo(textElemData, textElem)

      el.appendChild(divTooltip)
    })
  }

  getEventPosition(eventDate, progressBarStartDate, progressBarEndDate, progressBarWidth) {
    const eventTimestamp = eventDate.getTime()
    const startTimestamp = progressBarStartDate.getTime()
    const eventOffset = eventTimestamp - startTimestamp

    // Calcula a diferença entre a data de fim da barra de progresso e a data de início da barra de progresso, em milissegundos
    const endTimestamp = progressBarEndDate.getTime()
    const duration = endTimestamp - startTimestamp

    // Calcula a porcentagem da duração total da barra de progresso correspondente ao evento
    const eventPercentage = eventOffset / duration

    // Calcula a posição em pixels do evento na barra de progresso
    const eventPosition = eventPercentage * progressBarWidth

    return eventPosition
  }

  getSeverityClass(severity) {
    switch (severity) {
    case EVENT_SEVERITY.L:
      return 'background-color: #28C76F;'
    case EVENT_SEVERITY.M:
      return 'background-color: #FF9F43;'
    case EVENT_SEVERITY.H:
      return 'background-color: #EA5455;'
    default:
      return 'background-color: blue;'
    }
  }
}

videojs.registerComponent('EventBarCustom', EventBarCustom)
