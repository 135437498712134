import mapValues from 'lodash/mapValues'
import get from 'lodash/get'

const defaults = {
  duration: 5000,
  position: 'top-center',
  title: 'Notificação',
  icon: 'icon-bell',
  category: 'primary',
  onClick: (service, notification) => service.openNewTab(notification),
}

export const definitions = {
  'answer.comment': {
    message: (vue, notification) => {
      //const commentData = JSON.parse(notification.comment.data)
      //return commentData.body
      return get(notification,'data.comment.data.text', 'Acesse a respectiva avaliação e questão para visualizar o seu comentário.')
    },
    onClick: (service, notification) => service.markAsRead(notification),
    dropdownIcon: 'icon-message-square',
    dropdownTitle: 'Nova mensagem!',
  },

  'questionnaire_link.sent': {
    message: (service, notification) => {
      return `Você recebeu um novo questionário para responder: ${notification.questionnaire_name}`
    },
    dropdownIcon: 'icon-file-text',
    dropdownTitle: 'Novo questionário!',
  },

  'questionnaire.result': {
    message: (vue, notification) => {
      return `Os resultados do questionário ${notification.questionnaire_name} foram liberados!`
    },
    dropdownIcon: 'icon-file-text',
    dropdownTitle: 'Resultados liberados!',
  },

  'class.invite': {
    message: (vue, notification) => {
      return `Você foi convidado à turma ${notification.class_name}`
    },
    dropdownTitle: 'Convite de Turma',
    dropdownIcon: 'icon-users',
  },

  'text.message': {
    message: (vue, notification) => {
      return `${notification.comment.message}`
    },
    dropdownTitle: 'Nova mensagem!',
    dropdownIcon: 'icon-message-circle',
  },

  'unknown': {
    message: (vue, notification) => {
      return `TIPO DE NOTIFICAÇÃO DESCONHECIDO: ${
        notification.type || 'broadcastType undefined'
      }`
    },
    onClick: (service, notification) => service.markAsRead(notification),
  },

  'job_done': {
    message: (vue, notification) => {
      return `${notification.message}`
    },
    dropdownTitle: 'Processamento completo!',
    dropdownIcon: 'icon-server',
    onClick: (service, notification) => service.openNewTab(notification),
  },
}

export const withMissingDefaults = (definitions) => {
  return mapValues(definitions, (definition) => {
    return {
      ...definition,
      dropdownDuration:
        'dropdownDuration' in definition
          ? definition.dropdownDuration
          : defaults.duration,
      dropdownPosition:
        'dropdownPosition' in definition
          ? definition.dropdownPosition
          : defaults.position,
      dropdownTitle:
        'dropdownTitle' in definition
          ? definition.dropdownTitle
          : defaults.title,
      dropdownIcon:
        'dropdownIcon' in definition ? definition.dropdownIcon : defaults.icon,
      dropdownCategory:
        'dropdownCategory' in definition
          ? definition.dropdownCategory
          : defaults.category,
      onClick: 'onClick' in definition ? definition.onClick : defaults.onClick,
    }
  })
}

// onClick(service)(vue, notification)
