import { isArray } from 'lodash'
import Rest from '../Rest'

export default class ReportService extends Rest {
  /**
   * @type {String}
   */
  static resource = '/api/v1/reports'

  static build(vs: any = null, baseUrl) {
    return new this(this.resource, null, vs, baseUrl)
  }

  getPerformanceReport(questionnaireId, users=[]) {
    return this.get(`/questionnaire_performance/${questionnaireId}`, {
      users: users
    }).then(
      data => data
    )
  }

  getResultsReport(questionnaireId: Array<Number>|Number, users=[], questionsId=[], page: Number = 1, perPage: Number = -1) {
    let path = '/questionnaire_results/'
    const params: any = {
      users: users,
      questionsId: questionsId,
      page: page,
      per_page: perPage,
    }

    if (isArray(questionnaireId)) {
      params.questionnaires = questionnaireId
    } else {
      path = `/questionnaire_results/${questionnaireId}`
    }

    return this.get(path, params).then(
      data => data
    )
  }

  exportGrid(gridUrl, fileType, params) {
    return this.getBlob(`${gridUrl}/export/${fileType}`, params).then(
      data => data
    )
  }

  getQuestionnaireQuestions(questionnaireId) {
    return this.getBlob(`/questionnaire/${questionnaireId}/questions/export`).then(
      data => data
    )
  }
}
